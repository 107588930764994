_ = require("lodash");
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueProgress from "vue-progress-path";
import PreviousRouteBlock from "./components/blocks/previous-route-block";
import LoadingProcessing from "./components/LoadingProcessing";
import Toastify from "toastify-js";
import {
  errorHandler,
  showInfo,
  showResponseErrors,
  showError,
  showSuccess,
  handleURL,
} from "./utils/helpers";
import {
  AlertError,
  AlertErrors,
  Button,
  HasError,
} from "vform/src/components/bootstrap5";
import VuejsDialog from "vuejs-dialog";
import VShowSlide from "v-show-slide";
import api_request_params_data from "./assets/data/api-request-params.json";

window.API = require("./services/API");
window.axios = require("axios");
window.Toastify = Toastify;
window.Button = Button;
window.HasError = HasError;
window.AlertError = AlertError;
window.AlertErrors = AlertErrors;
window.errorHandler = errorHandler;
window.showInfo = showInfo;
window.showResponseErrors = showResponseErrors;
window.showError = showError;
window.showSuccess = showSuccess;
window.handleURL = handleURL;
window.api_request_params = api_request_params_data;
window.client_app_url = process.env.VUE_APP_CLIENT_APP_URL;

import "./sass/app.scss";
import "vue-progress-path/dist/vue-progress-path.css";
import "toastify-js/src/toastify.css";
import "./assets/css/vuejs-dialog.min.css";
import "./assets/css/vue-multiselect.min.css";
import "./assets/css/vue-tel-input.min.css";

Vue.config.productionTip = Boolean(parseInt(process.env.VUE_APP_DEV_MODE));

Vue.prototype.$apiRequestParams = window.api_request_params;
Vue.prototype.$clientAppUrl = window.client_app_url;
Vue.prototype.$appMainDomain = process.env.VUE_APP_MAIN_DOMAIN;
Vue.prototype.$appName = process.env.VUE_APP_NAME;

const EventBus = new Vue();

let appMain = new Vue({
  data: {
    $screenWidth: window.screen.width,
    $screenHeight: window.screen.height,
    $sidebarOpen: false,
    $sidebarCollapse: true,
    $sidebarAlwaysOpened: false,
    $dialogOptions: {
      cancelText: "Отмена",
      verification: "Продолжить",
      okText: "Подтвердить",
      animation: "zoom",
      backdropClose: false,
      customClass: "",
    },
  },
});

Vue.mixin({
  props: {
    $_PaginationObject: {},
    $_ModalObject: {},
    $_ModalObjectItems: {},
  },
  data: function() {
    return {
      EventBus: EventBus,
    };
  },
  computed: {
    $sidebarOpen: {
      get: function() {
        return appMain.$data.$sidebarOpen;
      },
      set: function(toggleStatus) {
        appMain.$data.$sidebarOpen = toggleStatus;
      },
    },
    $sidebarCollapse: {
      get: function() {
        return appMain.$data.$sidebarCollapse;
      },
      set: function(toggleStatus) {
        appMain.$data.$sidebarCollapse = toggleStatus;
      },
    },
    $sidebarAlwaysOpened: {
      get: function() {
        return appMain.$data.$sidebarAlwaysOpened;
      },
      set: function(toggleStatus) {
        appMain.$data.$sidebarAlwaysOpened = toggleStatus;
      },
    },
    $dialogOptions: {
      get: function() {
        return appMain.$data.$dialogOptions;
      },
    },
    $screenWidth: {
      get: function() {
        return appMain.$data.$screenWidth;
      },
    },
    $screenHeight: {
      get: function() {
        return appMain.$data.$screenHeight;
      },
    },
  },
})
  .use(VueProgress)
  .use(VuejsDialog)
  .use(VShowSlide);

Vue.component("previous-route-block", PreviousRouteBlock);
Vue.component("loading-processing", LoadingProcessing, {
  onProgress: true,
  size: 75,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
