<template>
    <div class="text-center">
        <loading-progress
                :progress="progress"
                :indeterminate="indeterminate"
                :counter-clockwise="counterClockwise"
                :hide-background="hideBackground"
                :size="size"
                rotate
                fillDuration="5"
                rotationDuration="1"/>
    </div>
</template>

<script>
    export default {
        name: 'loading-processing',

        props: {
            onProgress: {
                type: Boolean,
                default: true
            },
            indeterminate: {
                type: Boolean,
                default: true
            },
            progress: {
                type: Number,
                default: 0
            },
            counterClockwise: {
                type: Boolean,
                default: false
            },
            hideBackground: {
                type: Boolean,
                default: false
            },
            size: {
                type: Number,
                default: 80
            },
        },
    }
</script>

<style scoped>

</style>
