export const errorHandler = (error) => {
    if (Boolean(parseInt(process.env.VUE_APP_DEV_MODE))) {
        console.error(
            "======================== ERROR ============================="
        );

        console.log(
            error.response
                ? (error.response.data,
                  error.response.status,
                  error.response.headers)
                : error
        );

        console.error(
            "============================================================="
        );
    }

    if (!error.response) {
        return showError(500, error);
    }

    if (error.name === "Fetch User") {
        return showError(403, error.message);
    }

    if (error.response.status === 401) {
        return showInfo("Сессия устарела. Авторизуйтесь повторно");
    }

    if (error.response.status === 404 && !error.response.data) {
        return showError(
            404,
            `Ресурс ${error.config ? error.config.url : ""} не найден`
        );
    }

    if (error.response.status === 422 && error.response.data) {
        if (
            error.response.data.errors &&
            Object.keys(error.response.data.errors).length > 1
        ) {
            return showWarning(
                `Неверно указано ${
                    Object.keys(error.response.data.errors).length
                } полей`
            );
        } else {
            if (error.response) {
                return showResponseErrors(
                    typeof error.response.status === "number"
                        ? error.response.status
                        : 500,
                    error.response.data.errors
                );
            } else {
                return showWarning(`Неверно указаны данные`);
            }
        }
    }

    if (error.response.status === 413) {
        return showWarning("Слишком большой размер отправляемого запроса");
    }

    if (error.response.status === 403 || error.response.status === 401) {
        if (
            error.config &&
            error.config.url &&
            error.config.url !== "/users/auth"
        ) {
            return showWarning(`Ресурс (${error.config.url}) недоступен`);
        }

        return;
    }

    if (error.response.data && error.response.data.errors) {
        return showResponseErrors(
            error.response.status,
            error.response.data.errors
        );
    }

    if (error.response && error.message) {
        return showError(error.response.status, error.message);
    }

    return showError(500, "Ошибка обработки запроса");
};

export const showSuccess = (message) => {
    (typeof message === "string" && message.length) || (message = "Успешно");
    showNotification("success", message, "#06b345, #047d3a");
};

export const showInfo = (message, destination = null) => {
    (typeof message === "string" && message.length) || (message = "Выполнено");
    showNotification("notice", message, "#0988ff, #0052BC", destination);
};

export const showResponseErrors = (code, errors) => {
    if (typeof errors === "object") {
        for (const [key, error_items] of Object.entries(errors)) {
            error_items.forEach((error) =>
                showError(typeof code === "number" ? code : 500, error)
            );
        }
    } else {
        let message = !errors
            ? "Ошибка запроса. Попробуйте повторно"
            : errors.message
            ? errors.message
            : errors;

        return showError(typeof code === "number" ? code : 500, message);
    }
};

export const showError = (code, message) => {
    (typeof message === "string" && message.length) || (message = "Ошибка");
    showNotification("error", `${code} ${message}`, "#B40046, #8d0439");
};

export const showWarning = (message) => {
    (typeof message === "string" && message.length) || (message = "Ошибка");
    showNotification("error", `${message}`, "#ff7c09, #CC5F00");
};

export const showNotification = (type, message, colors, destination = null) => {
    Toastify({
        text: message.toString(),
        duration: 5000,
        destination: destination,
        newWindow: true,
        close: true,
        avatar: null,
        gravity: "top",
        position: "right",
        escapeMarkup: false,
        style: {
            background: "linear-gradient(to right, " + colors + ")",
        },
        stopOnFocus: true,
    }).showToast();
};

export const handleURL = (urlData) => {
  let url;
  try {
    // if (process.env.VUE_APP_DEV_MODE !== '1') {
      url = new URL(urlData);

      if (url.protocol === "http:") {
        return urlData.replace(/^http:\/\//i, "https://");
      }
    // }
  } catch (_) {
    return urlData;
  }

  return urlData;
};
