export default function auth({ to, next, store }) {
    if (!store.getters["auth/authUser"]) {
        store.dispatch("auth/getAuthUser").then(() => {
            if (!store.getters["auth/authUser"]) {
                next({ path: "/login" });
            } else {
                next();
            }
        });
    } else {
        next();
    }
}
