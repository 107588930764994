import Cookies from "js-cookie";

export default {
    async getItems(is_flow = null, is_basic = null) {
        let navigation = JSON.parse(Cookies.get("navigation") ?? null);

        if (!navigation) {
            navigation = await this.loadItems();
        }

        return navigation;
    },
    async loadItems() {
        try {
            const navigation_response = await API.apiClient.get("/navigation");

            if (
                navigation_response &&
                navigation_response instanceof Object &&
                navigation_response.data
            ) {
                Cookies.set(
                    "navigation",
                    JSON.stringify(navigation_response.data),
                    {
                        expires: 3,
                    }
                );

                return navigation_response.data;
            } else {
                return null;
            }
        } catch (error) {
            errorHandler(error);
        }
    },
};
