<template>
    <a href="#" @click="logout" @click.prevent="logout"
       class="btn btn-default pull-right" style="min-width: 60px; color: #000;">
        <i class="fas fa-sign-out-alt"></i>
    </a>
</template>

<script>
    export default {
        name: 'logout',

        methods: {
            logout() {
                this.$store.dispatch("auth/logout");
            },
        },
    };
</script>
