<template>
  <aside class="main-sidebar" style="z-index: 850;">
    <section class="sidebar">
      <ul class="sidebar-menu" v-if="onProgress === false">
        <li class="treeview menu-open" v-for="element in navigation">
          <a class="nav-group-title">
            <i :class="element.icon" style="margin-right: 2px;"></i>
            <span class="menu-title-text">{{ element.name }}</span>
          </a>
          <ul class="treeview-menu" style="display: block;" :key="transit_amount"
              v-on:click="!$sidebarAlwaysOpened ? ($sidebarOpen = $sidebarAlwaysOpened) : null">
            <li v-for="item in element.subs">
              <router-link v-bind:class="$router.currentRoute.name !== item.route_name ? '' : 'text-bold'"
                           :to="{ name: item.route_name}">
                <i :class="item.icon" class="menu-sub-icon"></i>
                <span>{{ item.name }}</span>
              </router-link>
            </li>
          </ul>
        </li>

        <li>
          <a :href="$clientAppUrl" target="_blank">
            <i class="fa fa-share"></i> <span>{{ $appMainDomain }}</span>
          </a>
        </li>

        <li class="treeview menu-open">
          <a class="nav-group-title pointer text-orange" onclick="location.reload(true);">
            <i class="fas fa-broom" style="margin-right: 2px;"></i>
            <span class="menu-title-text">Сбросить кэш</span>
          </a>
        </li>
      </ul>
    </section>
  </aside>
</template>

<script>
import NavigationService from "@/services/NavigationService";
import {mapGetters} from 'vuex';

export default {
  name: 'navbar',

  data: () => ({
    navigation: [],
    transit_amount: 0,
    currentSwipeRange: 0,

    onProgress: true,
  }),

  async created() {
    try {
      this.navigation = await NavigationService.getItems();
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    ...mapGetters("auth", ["authUser"]),
  },

  watch: {
    $route() {
      this.transit_amount++;
    }
  },

  methods: {}
}
</script>

<style scoped>
.main-sidebar {
  -webkit-transition: all 0.245s 0s ease-out;
  -moz-transition: all 0.245s 0s ease-out;
  -o-transition: all 0.245s 0s ease-out;
  transition: all 0.245s 0s ease-out;
}

.treeview {
  -webkit-transition: all 0.305s 0s linear;
  -moz-transition: all 0.305s 0s linear;
  -o-transition: all 0.305s 0s linear;
  transition: all 0.305s 0s linear;
}
</style>
