import axios from "axios";
import store from "./../store";

export const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
});

apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (
            error.response &&
            [401, 403, 419].includes(error.response.status) &&
            store.getters["auth/authUser"] &&
            !store.getters["auth/guest"]
        ) {
            store.dispatch("auth/logout");
        }
        return Promise.reject(error);
    }
);

export async function handleFilterRequest(
    relations,
    relations_count,
    search_text,
    filters_query,
    is_paginated,
    sorting,
    items_per_page
) {
    try {
        return {
            relations:
                relations && relations instanceof Array ? relations : null,
            relations_count:
                relations_count && relations_count instanceof Array
                    ? relations_count
                    : null,
            search_text:
                search_text && search_text instanceof String
                    ? search_text
                    : null,
            filters_query:
                filters_query && filters_query instanceof Object
                    ? filters_query
                    : null,
            is_paginated:
                is_paginated && is_paginated instanceof Boolean
                    ? is_paginated
                    : null,
            sorting:
                sorting && sorting instanceof String
                    ? sorting
                    : api_request_params.filters_paginate.sorting,
            items_per_page:
                items_per_page && items_per_page instanceof Number
                    ? items_per_page
                    : api_request_params.filters_paginate.items_per_page,
        };
    } catch (error) {
        errorHandler(error);
    }
}
