<template>
  <div class="fixed-top" v-if="authUser">
    <header class="main-header">
      <router-link class="logo" :to="{ name: 'dashboard'}">
        <span class="logo-mini">
          <img src="../assets/img/logo-mini.png" :alt="appName" />
        </span>
        <span class="logo-lg">
          <strong>{{ appName }}</strong>
        </span>
      </router-link>
    </header>

    <nav class="navbar navbar-expand">
      <div class="container-fluid">
        <a class="sidebar-toggle menu-border pointer d-md-none" v-on:click="sidebarMobileControl" role="button">
          <i v-bind:class="$sidebarOpen ? 'far fa-caret-square-left' : 'fas fa-caret-square-right'"
             style="font-size: 32px;">
          </i>
        </a>
        <a class="sidebar-toggle menu-border navbar-toggler pointer d-md-block"
           v-on:click="sidebarDesktopControl" role="button">
          <i v-bind:class="$sidebarCollapse ? 'fas fa-caret-square-right' : 'far fa-caret-square-left'"
             style="font-size: 28px;"></i>
        </a>

        <div class="collapse navbar-collapse">
          <ul class="navbar-nav">
<!--            <li class="nav-item" v-if="debugStatus === true">-->
<!--              <span class="label label-primary" style="padding: 3px 4px; font-size: 12px;">-->
<!--                DEV Mode-->
<!--              </span>-->
<!--            </li>-->

            <li class="nav-item">
              <a class="nav-link" role="button" v-on:click="toggleSearchBlock" style="margin-top: -3px;">
                <i class="fas fa-search"></i>
              </a>
              <div class="navbar-search-block" :style="showSearch ? 'display: flex;' : 'display: none;'">
                <form class="form-inline" v-on:submit.prevent="">
                  <div class="input-group input-group-sm">
                    <input class="form-control form-control-navbar"
                           type="number"
                           v-model="searchQuery"
                           placeholder="Поиск по номеру телефона"
                           v-on:keyup.enter="getSearchResults"
                           aria-label="searchByPhone">
                    <div class="input-group-append">
                      <button class="btn btn-navbar"
                              :disabled="!searchQuery || searchQuery.length < 6"
                              type="button"
                              v-on:click="getSearchResults">
                        <i class="fas fa-search" style="filter: invert(1);"></i>
                      </button>
                      <button class="btn btn-navbar"
                              type="button"
                              v-on:click="toggleSearchBlock">
                        <i class="fas fa-times" style="filter: invert(1);"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link" role="button" v-on:click="showCreateQuickClientModal" style="margin-top: -3px;">
                <i class="fas fa-user-plus"></i>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" role="button" v-on:click="$router.push('/cars/inspections')" style="margin-top: -2px;">
                <i class="fas fa-car-on"></i>
              </a>
            </li>

            <li class="nav-item dropdown employee employee-menu">
              <a class="nav-link menu-border dropdown-toggle" style="cursor: pointer;"
                 v-on:click="toggleEmployeeMenu">
                <span :class="authUser.isDayOff ? 'text-warning' : ''">{{ authUser.name }}</span>
              </a>
              <ul class="dropdown-menu animate slideIn" id="showMenuBlock" v-bind:class="showMenu ? 'show' : ''">
                <li class="employee-header">
                  {{ authUser.email }}
                  <br />
                  <small style="color: #999;">
                    {{ authUser.role.name }}
                  </small>
                  <br />
                  <small style="color: rgb(219, 219, 221);">
                    Штрафные начисления: <strong>{{ authUser.current_bonuses }}</strong>$
                  </small>
                </li>
                <li class="employee-header">
                  <router-link :to="{ name: 'employee.details', params: { employeeId: authUser.id }}"
                               class="btn btn-primary"
                               style="color: #fff"
                               title="Профиль"
                               type="button">
                    <i class="fa fa-user-circle"></i>
                    Профиль
                  </router-link>
                </li>
                <li class="employee-header">
                  <button type="button"
                          class="btn mb-1"
                          :class="(!authUser.isDayOff && !authUser.dayOffResponsibleID) ? 'btn-info' : 'btn-warning'"
                          v-on:click="showSetDayOffModal">
                    <span v-if="!authUser.isDayOff && !authUser.dayOffResponsibleID">
                      Установить выходной
                    </span>
                    <span v-else>
                      Отключить {{ authUser.isVacation ? 'отпуск' : 'выходной' }}
                    </span>
                  </button>
                </li>

                <li class="employee-footer">
                  <div class="text-right">
                    <logout></logout>
                  </div>
                </li>
              </ul>
            </li>

            <li class="nav-item dropdown employee employee-menu">
              <a class="nav-link menu-border dropdown-toggle" style="cursor: pointer;"
                 v-on:click="toggleNotificationsMenu">
                <i class="far fa-bell"></i>
                <transition name="fade">
                  <template v-if="!onProgress">
                      <span v-if="unread_notifications_count > 0" class="badge badge-warning navbar-badge">
                        {{ unread_notifications_count }}
                      </span>
                    <span v-else class="badge badge-success navbar-badge">0</span>
                  </template>
                </transition>
              </a>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right animate slideIn"
                   v-bind:class="showNotificationsMenu ? 'show' : ''"
                   id="showNotificationsMenuBlock"
                   style="left: inherit; right: 0;">
                <template v-if="employee_notifications" v-for="notification in employee_notifications">
                  <router-link v-if="notification.item_id"
                               :to="{ name: notification.route_name, params: { taskId: notification.item_id }}"
                               class="dropdown-item"
                               :style="'background:' + (!notification.has_read ? '#fffbed' : '#fff')">
                    <div class="media" v-on:click="setHasRead(notification.id)">
                      <i :class="notification.type_icon" class="font-scale-3 text-muted padding-r-10 mt-2" />
                      <div class="media-body">
                        <p class="font-small">{{ notification.message }}</p>
                        <p class="font-small-xxs text-muted text-right">
                          <i class="far fa-clock mr-1"></i> {{ notification.created_date }}
                        </p>
                      </div>
                    </div>
                  </router-link>
                  <a v-else
                     href="#"
                     class="dropdown-item"
                     :style="'background:' + (!notification.has_read ? '#fffbed' : '#fff')"
                     v-on:click="setHasRead(notification.id)">
                    <div class="media">
                      <i :class="notification.type_icon" class="font-scale-3 text-muted padding-r-10 mt-2" />
                      <div class="media-body">
                        <p class="font-small">{{ notification.message }}</p>
                        <p class="font-small-xxs text-muted text-right">
                          <i class="far fa-clock mr-1"></i> {{ notification.created_date }}
                        </p>
                      </div>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
                </template>

                <router-link class="dropdown-item dropdown-footer bg-white" :to="{ name: 'notifications'}">
                  Все уведомления
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <modal :adaptive="true"
           :clickToClose="true"
           :draggable="false"
           :name="'create_quick_client_modal'"
           :resizable="false"
           :scrollable="false"
           :shiftX="0.5"
           :shiftY="0.5"
           :height="'auto'"
           overlayTransition="fade"
           transition="fade"
           @closed="hideCreateQuickClientModal">
      <div class="box box-primary box-modal mb-0">
        <div class="box-header with-border mb-3">
          <h4>Создать клиента</h4>
        </div>

        <div class="box-body">
          <create-quick-client-form/>
        </div>
      </div>
    </modal>

    <modal :adaptive="true"
           :clickToClose="true"
           :draggable="false"
           :name="'set_day_off_modal'"
           :resizable="false"
           :scrollable="false"
           :shiftX="0.5"
           :shiftY="0.5"
           :height="'auto'"
           overlayTransition="fade"
           transition="fade"
           @closed="hideSetDayOffModal">
      <div class="box box-primary box-modal mb-0">
        <div class="box-header with-border mb-3">
          <h4 v-if="authUser.isDayOff && authUser.dayOffResponsibleID">
            Отключить режим {{ authUser.isVacation ? 'отпуска' : 'выходного' }}
          </h4>
          <h4 v-else>
            Установить режим выходного
          </h4>
        </div>

        <div class="box-body">
          <set-day-off-form :employee_id="authUser.id"
                            :is_day_off="authUser.isDayOff"
                            :day_off_responsible_id="authUser.dayOffResponsibleID"/>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logout from "./Logout";
import VModal from 'vue-js-modal';

import Echo from "laravel-echo";
import Pusher from "pusher-js";
import Vue from "vue";
import auth from "../middleware/auth";

window.Pusher = Pusher;

const parts = document.cookie.split(`; XSRF-TOKEN=`);
const xsrfToken = parts.pop().split(";").shift();

Vue.use(VModal);

Vue.component('create-quick-client-form', (resolve) => {
  return require(['./../views/clients/create-quick-form.vue'], resolve);
});
Vue.component('set-day-off-form', (resolve) => {
  return require(['./../views/employees/set-day-off-form.vue'], resolve);
});

window.Echo = new Echo({
  broadcaster: "pusher",
  key: "26aea3b71e92d11923a4",
  cluster: "eu",
  encrypted: true,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        axios.create({
          baseURL: process.env.VUE_APP_API_URL,
          withCredentials: true,
        }).post("/broadcasting/auth", {
          socket_id: socketId,
          channel_name: channel.name,
        }, {
          headers: {
            Accept: "application/json, text/plain, */*",
            "X-Requested-With": "XMLHttpRequest",
            "X-XSRF-TOKEN": decodeURIComponent(xsrfToken),
          },
        }).then((response) => {
          callback(false, response.data);
        }).catch((error) => {
          callback(true, error);
        });
      },
    };
  },
});

let notifyAudio = new Audio("/notify.wav");

export default {
  name: "head-block",

  components: {
    Logout,
  },

  data: () => ({
    appName: process.env.VUE_APP_SHORT_NAME,
    showNotificationsMenu: false,
    showMenu: false,
    showSearch: false,
    searchQuery: null,
    debugStatus: Boolean(parseInt(process.env.VUE_APP_DEV_MODE)),
    unread_notifications_count: 0,
    employee_notifications: [],
    onProgress: true,
  }),

  async created() {
    try {
      await this.getEmployeeNotifications();
      await this.getEmployeeNotificationsCount();

      window.Echo.private("App.Models.Employee." + this.authUser.id).notification((notification) => {
        showInfo(notification.message, notification.buttonURL);
        notifyAudio.play();

        this.getEmployeeNotifications();
        this.getEmployeeNotificationsCount();
      });

      this.EventBus.$on("set-sidebar-open", await this.setSidebarOpen);
      this.EventBus.$on("set-sidebar-close", await this.setSidebarClose);

      this.EventBus.$on('hide-quick-create-client-modal', () => {
        this.hideCreateQuickClientModal();
      });
      this.EventBus.$on('hide-set-day-off-modal', () => {
        this.hideSetDayOffModal();
      });
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    ...mapGetters("auth", ["authUser"]),
  },

  methods: {
    auth,
    async getEmployeeNotifications() {
      try {
        const employee_notifications_response = await API.apiClient.get(
          "/employee/" + this.authUser.id + "/notifications",
          {
            params: {
              sorting: "desc",
              items_limit: 5,
            },
          },
        );

        this.employee_notifications = employee_notifications_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getEmployeeNotificationsCount() {
      try {
        const employee_notifications_count_response = await API.apiClient.get(
          "/employee/" + this.authUser.id + "/notifications/count",
          {
            params: {
              sorting: "desc",
              items_limit: 5,
            },
          },
        );

        this.employee_notifications_count = employee_notifications_count_response.data;
        this.unread_notifications_count = this.employee_notifications_count.unread;
      } catch (error) {
        errorHandler(error);
      }
    },
    setHasRead(notificationId) {
      API.apiClient.put("/employee/" + this.authUser.id + "/notifications/" + notificationId).then(() => {
        this.unread_notifications_count -= 1;
        this.getEmployeeNotifications();
      }).catch((error) => {
        errorHandler(error);
      });
    },

    async getSearchResults() {
      try {
        const search_results_response = await API.apiClient.get("/search", {
          params: {
            phone: this.searchQuery,
          },
        });

        this.$router.push({
          name: 'search',
          path: '/search',
          params: {
            search_query: this.searchQuery,
            search_results: search_results_response.data,
          }
        });

        this.showSearch = false;
        this.searchQuery = null;
      } catch (error) {
        errorHandler(error);
      }
    },

    async toggleNotificationsMenu() {
      this.showNotificationsMenu = this.showNotificationsMenu === false;
    },
    async toggleEmployeeMenu() {
      this.showMenu = this.showMenu === false;
    },
    async toggleSearchBlock() {
      this.showSearch = this.showSearch === false;
    },
    async sidebarMobileControl() {
      this.$sidebarOpen = this.$sidebarOpen === false;
    },
    async sidebarDesktopControl() {
      this.$sidebarCollapse = this.$sidebarCollapse === false;
    },
    async setSidebarOpen() {
      this.$sidebarCollapse = false;
      this.$sidebarOpen = true;
    },
    async setSidebarClose() {
      this.$sidebarCollapse = true;
      this.$sidebarOpen = false;
    },
    async sidebarDesktopControl() {
      this.$sidebarCollapse = this.$sidebarCollapse === false;
    },
    async setSidebarOpenedStatus() {
      if (this.$sidebarAlwaysOpened === false) {
        this.$sidebarCollapse = false;
        this.$sidebarAlwaysOpened = true;
      } else {
        this.$sidebarCollapse = true;
        this.$sidebarAlwaysOpened = false;
      }

      API.apiClient.put("/employee/" + this.employee.id + "/sidebar").then(() => {
        showSuccess();
      }).catch((error) => {
        errorHandler(error);
      });
    },

    showCreateQuickClientModal() {
      this.$modal.show('create_quick_client_modal');
    },
    hideCreateQuickClientModal() {
      this.$modal.hide('create_quick_client_modal');
    },

    showSetDayOffModal() {
      this.$modal.show('set_day_off_modal');
    },
    hideSetDayOffModal() {
      this.$modal.hide('set_day_off_modal');
    },
  },
};
</script>

<style scoped>
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
}

.dropdown-menu-lg .dropdown-divider {
  margin: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.dropdown-menu-lg p {
  margin: 0;
  white-space: normal;
}

.dropdown-menu-lg p {
  margin: 0;
  white-space: normal;
}

.navbar-search-block {

}
</style>

<style>
@media (min-width: 768px) {
  .animate {
    animation-duration: 0.195s;
    -webkit-animation-duration: 0.195s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-0.9rem);
    opacity: 0;
  }

  20% {
    transform: translateY(-0.75rem);
    opacity: 0.15;
  }

  35% {
    transform: translateY(-0.65rem);
    opacity: 0.3;
  }

  55% {
    transform: translateY(-0.5rem);
    opacity: 0.5;
  }

  65% {
    transform: translateY(-0.35em);
    opacity: 0.65;
  }

  80% {
    transform: translateY(-0.25rem);
    opacity: 0.8;
  }

  90% {
    transform: translateY(-0.15rem);
    opacity: 0.9;
  }

  95% {
    transform: translateY(-0.10rem);
    opacity: 0.95;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  20% {
    -webkit-transform: translateY(-0.85rem);
    -webkit-opacity: 0.15;
  }

  35% {
    -webkit-transform: translateY(-0.7rem);
    -webkit-opacity: 0.25;
  }

  55% {
    -webkit-transform: translateY(-0.55rem);
    -webkit-opacity: 0.4;
  }

  65% {
    -webkit-transform: translateY(-0.45rem);
    -webkit-opacity: 0.55;
  }

  80% {
    -webkit-transform: translateY(-0.35rem);
    -webkit-opacity: 0.7;
  }

  90% {
    -webkit-transform: translateY(-0.2rem);
    -webkit-opacity: 0.85;
  }

  95% {
    -webkit-transform: translateY(-0.1rem);
    -webkit-opacity: 0.95;
  }

  100% {
    -webkit-transform: translateY(0rem);
    -webkit-opacity: 1;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
</style>
