<template>
    <div class="full-screen-height">
        <body v-if="authUser" class="hold-transition skin-blue sidebar-mini"
              v-bind:class="[$sidebarOpen ? 'sidebar-open' : '', $sidebarCollapse ? 'sidebar-collapse' : '']">
        <div class="wrapper">
            <head-block></head-block>

            <transition name="slide-fade">
                <navbar></navbar>
            </transition>

            <div class="content-wrapper mb-5">
                <section class="content">
                    <loading ref="loading"/>

                    <transition name="component-fade" mode="out-in">
                        <router-view></router-view>
                    </transition>
                </section>
            </div>

            <transition name="fade">
                <div id="back-top" v-if="show_lift" style="display: block;">
                    <a href="#" class="fa fa-angle-double-up"></a>
                </div>
            </transition>
        </div>
        </body>

        <login v-else></login>
    </div>
</template>

<script>
    import HeadBlock from './components/HeadBlock';
    import Navbar from "./components/Navbar";
    import Loading from './components/Loading';
    import {mapGetters} from 'vuex';
    import Login from "./views/auth/login";

    export default {
        name: 'app',

        components: {
            Login,
            Loading,
            HeadBlock,
            Navbar,
        },

        data: () => ({
            show_lift: false,
            currentSwipeRange: 0,
        }),

        metaInfo() {
            const {appName} = this.$appName

            return {
                title: appName,
                titleTemplate: `%s - ` + this.$appName
            }
        },

        created() {
            addEventListener('scroll', this.handleScroll);
        },

        computed: {
            ...mapGetters("auth", ["authUser"]),
        },

        destroyed() {
            removeEventListener('scroll', this.handleScroll);
        },

        mounted() {
            this.$loading = this.$refs.loading;
        },

        methods: {
            handleScroll() {
                return this.show_lift = window.scrollY > 400;
            }
        }
    };
</script>

<style scoped>
    .component-fade-enter-active, .component-fade-leave-active {
        transition: opacity .35s ease;
    }

    .component-fade-enter, .component-fade-leave-to
        /* .component-fade-leave-active до версии 2.1.8 */
    {
        opacity: 0;
    }

    .hold-transition {
        -webkit-transition: all 0.180s 0s ease;
        -moz-transition: all 0.180s 0s ease;
        -o-transition: all 0.180s 0s ease;
        transition: all 0.180s 0s ease;
    }
</style>