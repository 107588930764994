<template>
  <body class="hold-transition sidebar-mini" v-else>
  <div class="wrapper" style="min-height: 100vh;">
    <div class="login-box" v-if="show_auth_form">
      <div class="login-logo">
        <strong>{{ appName }}</strong>
      </div>

      <div class="login-box-body">
        <form @keydown="form.onKeydown($event)" @submit.prevent="login" autocomplete="on">
          <div class="form-group">
            <label for="email">
              <input :class="{ 'is-invalid': form.errors.has('email') }"
                     class="form-control" id="email" name="email" placeholder="Email"
                     autocomplete="on"
                     type="email" v-model="form.email">
            </label>
          </div>

          <div class="form-group">
            <label for="password">
              <input :class="{ 'is-invalid': form.errors.has('password') }" class="form-control"
                     id="password" name="password" placeholder="Пароль" type="password"
                     autocomplete="on"
                     v-model="form.password">
            </label>
          </div>

          <AlertErrors :form="form" message="Неверные имя пользователя или пароль"/>

          <div class="form-group mb-4">
            <input id="remember" name="remember" style="transform: scale(1.1); margin-left: 2px;"
                   type="checkbox" v-model="form.remember">
            <label for="remember" style="font-size: 14px; margin-left: 5px;">Запомнить</label>

            <Button :disabled="!validateData || is_busy"
                    :form="form"
                    class="btn btn-lg btn-primary btn-submit font-scale-1 pull-right">
              <i v-if="!is_busy" class="fas fa-sign-in-alt"></i>
              <i v-else class="fas font-scale fa-circle-notch fa-spin"/>
            </Button>
          </div>
        </form>
      </div>
    </div>
    <div class="loading-box" v-else>
      <loading-processing :size="120"></loading-processing>
    </div>
  </div>
  </body>
</template>

<script>
import Form from 'vform';
import AuthService from "./../../services/AuthService";
import {Button, AlertErrors} from 'vform/src/components/bootstrap5';

export default {
  name: 'login',

  components: {
    Button, AlertErrors,
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data() {
    return {
      page_title: 'Авторизация',

      appName: process.env.VUE_APP_SHORT_NAME,

      form: new Form({
        email: null,
        password: null,
        remember: false,
      }),

      show_auth_form: false,
      is_busy: false,
    };
  },

  beforeCreate() {
    setTimeout(() => this.show_auth_form = true, 400);
  },

  computed: {
    validateData: function () {
      return this.form.email && this.form.password && this.form.email.length >= 5 &&
          this.form.password.length >= 5;
    },
  },

  methods: {
    async login() {
      this.is_busy = true;
      const payload = {
        email: this.form.email,
        password: this.form.password,
      };
      this.error = null;
      try {
        await AuthService.login(payload);

        const authUser = await this.$store.dispatch("auth/getAuthUser");

        if (authUser) {
          await this.$store.dispatch("auth/setGuest", {value: "isNotGuest"});
          await this.$router.push("/");
        } else {
          this.is_busy = false;
          const error = Error(
              "Unable to fetch user after login, check your API settings."
          );
          error.name = "Fetch User";
          throw error;
        }
      } catch (error) {
        this.is_busy = false;
        this.error = errorHandler(error);
      }
    },
  },
};
</script>

<style>
label {
  display: unset !important;
}

.loading-box {
  margin-top: 8rem;

}
</style>
