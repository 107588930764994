<template>
  <a v-if="showButton"
     type="button"
     title="Вернуться назад"
     class="btn btn-default margin-r-5"
     v-on:click="$router.back()"
     style="padding-left: 12px !important; padding-right: 12px !important;">
    <i class="fas fa-arrow-left font-scale-1"></i>
  </a>
</template>

<script>
export default {
  name: 'previous-route-block',

  data: () => ({
    showButton:  window.history.length > 2,
  }),
}
</script>